@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.section3 {
    width: 100%;
    height: auto;
    background: rgba(43, 55, 88, 0);
    position: relative;
  }
  
  .section3:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }

.section3-content {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.section3-left {
    width: 100%;
    text-align: center;
    margin: auto;
}

.section3-left img {
    width: 100%;
    height: auto;
    display: block;
}

.section3-right {
    width: 100%;
    height: auto;
    margin: auto;
    padding: 1rem;
}


.section3-right h2 {
    font-size: 1rem;
    color: var(--primary-color);
    padding: 1rem 0;
    font-weight: normal;
}

.section3-right h1 {
    text-transform: uppercase;
    color: var(--primary-dark);
    font-size: 1rem;
    letter-spacing: 3px; 
    font-weight: normal;
}

.shape3 {
    width: 200px;
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 1;
    opacity: 0.8;
}


@media screen and (max-width:940px) {

    .section3-content {
        flex-wrap: wrap;
    }

    .section3-right {
        width: 100%;
        padding: 3rem 1rem;
    }

    .section3-right h1 {
        text-align: center; 
    }

    .section3-right h2 {
        text-align: center; 
    }

    .section3-right p {
        text-align: justify;
    }



}
