.footer {
    width: 100%;
    height: auto;
    background-color: var(--primary-color);    
    padding: 1rem;
}


.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.logo-footer {
    width: 250px;
}

.logo-footer img {
    width: 100%;
    height: auto;
}

.footer .top .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--secondary-color);
    cursor: pointer;
}

.footer h2 {
    font-size: 2rem;
}

.col {
    width: 300px;
}

.footer .col-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem;
    width: 100%;
}

.footer h3 {
    margin: 1rem 0 2rem 0;
    text-transform: uppercase;
    text-align: center;
    color: rgb(235, 225, 225);
    font-size: 1rem;
    font-weight: normal;
}


.footer p {
    margin: .5rem 0;
    color: rgb(235, 225, 225);
    text-align: center;

}

.copyright {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}

.copyright p {
    color: rgb(235, 225, 225);
    text-align: left;
}

.footer hr {
    width: 100%;
    opacity: 0.2;
    margin: 2rem 0 2rem 0;
}

.copyright .icon {
    font-size: 3rem;
    color: var(--secondary-color);
    cursor: pointer;
}

.iconHide {
    display: none;
}

.copyrightIcon {
    margin: auto;
    text-align: center;
    transition: transform 0.6s ease;
}

.footer .icon {
    transition: transform 0.6s ease;
}

.footer .icon:hover {
    transform: scale(1.3);
  }

.phoneNumber span {
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    color: var(--secondary-color);
    font-size: 1.2rem;
  }

.phoneNumber {
    display: inline-flex;
    align-items: center;
  }

.license {
    width: 100%;
    text-align: center;
    padding: 1rem;
}

.license a {
    text-align: center;
    width: 100%;
}

.license a:hover {
    color: #d58f76;
}

.dhcsLogo {
    width: 200px;
    margin: auto;
}

.dhcsLogo img {
    width: 100%;
}


@media screen and (max-width: 940px) {



}


@media screen and (max-width: 600px) {

    .iconShow {
        display: none;
    }

    .iconHide {
        display: block;
    }

}

