.contactform {
  background: #ffffff;
  padding: 3rem 0 0 0;
}

.get_form_inner {
  display: block;
  padding: 3rem 3rem;
  box-shadow: -4px -2px 20px -7px #cfd5df;
  margin: 2rem 5rem 2rem 5rem;
}

input {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

input[type="text"], input[type="email"], input[type="tel"] {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  color: #333;
  height: 42px;
  padding: 0 0 0 20px;
  width: 100%;
  outline: 0;
}

textarea {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  color: #333;
  padding: 12px 0 0 20px;
  width: 100%;
  outline: 0;
  margin-bottom: 20px;
  resize: none;
}

.get_form_inner_text h3 {
  color: #333;
  font-size: 26px;
  font-weight: 600;
}

.get_form_inner_text p {
  text-align: left;
}

input[type="submit"] {
  background-color: var(--primary-color);
  border: var(--primary-color);
  color: #fff;    
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px 20px;
  outline: 0;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

input[type="submit"]:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: #fff;
}

.contact-cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.get_form_inner2 {
  display: block;
  width: 300px;
  height: 150px;
  padding: 2rem 1rem;
  background: #fff;
  box-shadow: -4px -2px 20px -7px #cfd5df;
  margin: 1rem 1rem 3rem 1rem;
}

.get_form_inner2 h2 {
  color: var(--primary-color);
  text-align: center;
}

.get_form_inner2 p, .get_form_inner2 a {
  color: rgb(39, 38, 38);
  text-align: center;
  margin: 1rem auto;
}


/* Additional CSS for aligning span and a tag */
.get_form_inner2 p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem; /* Add gap between the icon and the text */
}

.get_form_inner2 p span, .get_form_inner2 p a {
  display: inline-flex;
  align-items: center;
}

.iframe-container {
  display: flex;
  justify-content: center;
}

iframe {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


@media screen and (max-width: 940px) {
  .get_form_inner {
    margin: 2rem 1rem 2rem 1rem;
  }
}
