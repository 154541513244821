@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');

.section-bg {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
}

.section-bg:before {
  content: '';
  position: absolute;
  background: url('../../assets/section_bg2.webp') no-repeat center top/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 1;
}

#cards {
  padding: 5rem 1rem;
}

.main-cards-header {
  padding: 1rem;
}

.main-cards-header p {
  padding: 1rem;
  text-align: center;

}

.main-cards-header h1 {
  text-align: center;
  font-family: 'Roboto', serif;
  font-size: 1.5rem;
  letter-spacing: 5px;
  font-weight: normal;
}

/* CARDS */

.cards-flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.services-card-image {
  position: relative;
}
.services-card {
  width: 300px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.100);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.100);
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.100);
  transition: box-shadow 0.3s ease, background-color 0.6s ease;
}

.card-body {
  padding: 16px;
  transition: background-color 0.6s ease;
}

.services-card:hover .card-body {
  background-color: var(--primary-color); /* Background color on hover */
}

.services-card:hover .card-title {
  color: #f7f4f1; /* Text color of title on hover */
}

.services-card:hover .card-caption {
  color: #f7f4f1; /* Text color of title on hover */
}

.services-card:hover .card-text {
  color: #f5f0f0; /* Text color of text on hover */
}

.card-body .card-btn button {
  padding: 1rem;
  font-size: 14px;
}

.services-card-btn button:hover {
  background-color: #d2691e; /* Button color on hover */
}

.services-card img {
  width: 100%;
  height: auto;
  display: block;
}

.services-card h1 {
  font-size: 1rem;
  color: rgb(61, 58, 58);
  text-align: center;
  padding: 1rem;
  font-weight: normal;
}

.services-card p {
  color: rgb(61, 58, 58);
  text-align: center;
}


.arrow a {
  color: var(--primary-color);
}

.arrow {
  position: absolute; /* Position the arrow icon */
  bottom: -13.5%;
  right: -5px; /* Start off-screen to the right */
  border-radius: 5px; /* Circular background */
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for the arrow container */
  transition: right 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform: translateY(-50%); /* Center vertically */
  opacity: 0; /* Hidden initially */}


.services-card:hover .arrow {
  font-size: 2rem;
  right: 0px; /* Slide the arrow into view */
  background-color: #d58f76; /* Background color of the arrow on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for the arrow container */
  transition: right 0.6s ease, opacity 0.6s ease, background-color 0.6s ease;
  transform: translateY(-50%); /* Center vertically */
  opacity: 1; /* Hidden initially */}

.services-card-btn {
  text-align: center;
}

.services-card:hover button{
  background-color: var(--secondary-color);
  border: none;
  color: white;
}

.card-btn {
  text-align: center;
}


@media screen and (max-width:940px) {
  .cards-flex {
    flex-wrap: wrap;
  }
  
  .services-card {
    width: 40%;
  }
}


@media screen and (max-width:700px) {
  .cards-flex {
    flex-wrap: wrap;
  }
  
  .services-card {
    width: 80%;
  }

  
.arrow {
  position: absolute; /* Position the arrow icon */
  bottom: -8%;
}

}







