@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.about {
  width: 100%;
  height: 80vh;
  background: rgba(21, 3, 27, 0.233);
  position: relative;
}
  
  .about:before {
    content: '';
    position: absolute;
    background: url('../../assets/about-us-hero.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.about .about-content {
  position: absolute;
    width: 100%;
    top: 60%;
    padding: 1rem;
    text-align: center;
    bottom: 20%;
}

.about h1 {
  color: white;
  font-size: 3rem;
  font-weight: normal;
}





/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.about h1 {
    font-size: 3rem;
}
}