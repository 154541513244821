/* ImageGrid.css */

.sectionBg {
    width: 100%;
    height: auto;
    position: relative;
    background-color: var(--primary-color);
    padding-top: 2rem;

}
  
  .sectionBg:before {
    content: '';
    position: absolute;
    background: url('../../assets/hero_bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.5;
  }
  

.imageGrid {
    height: auto;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    padding:  0 1rem;
}

.imageGridHeader {
    text-align: center;
    padding: 0 1rem;
}

.imageGridHeader h1 {
    color: rgb(236, 228, 228);
    font-weight: normal;
    padding: .5rem 0;
}

.gridHeaderBtn button {
    background-color: #d58f76;
    color: white;
}

.imageGridHeader p {
    color: rgb(202, 194, 194);
}

.gridLeft {
    width: 100%;
    margin: auto;
    margin-top: -3rem;
}

.gridItem1, .gridItem2, .gridItem3{
    width: 100%;
    padding: 1rem 0;
}

.gridLeftImage1 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.gridLeftImage1 img{
    width: 40%;
    text-align: right;
    display: block;
    

}

/*  */

.gridLeftImage2 {
    width: 100%;
    text-align: center;
}

.gridLeftImage2 img{
    margin: auto;
    width: 40%;
}


/*  */


.gridLeftImage3 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.gridLeftImage3 img{
    width: 30%;
    display: block;
}


/* Center */

.gridCenter {
    width: 100%;
    margin: auto;
    text-align: center;
}

.gridCenter img {
    width: 65%;
    display: block;
    margin: auto;
}

.gridRight {
    width: 100%;
    margin: auto;
}

.gridCenterBtn {
    text-align: center;
}

.gridCenter button {
    background-color: #d58f76;
    color: white;
    font-size: 14px;
}

.gridCenter .hide {
    display: none;
}


/* Right */

.gridRight {
    width: 100%;
    height: 100%;
    margin-top: -3rem;
}


.gridRightImage1 {
    width: 100%;
    text-align: left;  
    padding: 1rem 0;

}

.gridRightImage1 img{
    margin: auto;
    width: 40%;
}



.gridRightImage2 {
    width: 100%;
    text-align: center; 
    padding: 1rem 0;
 
}

.gridRightImage2 img{
    width: 40%;
    margin: auto;
}

.gridRightImage3 {
    width: 100%;
    padding: 1rem 0;
}

.gridRightImage3 img{
    width: 40%;
    text-align: left; 
}


/* TITLES */


.gridImage h1 {
    font-size: 1rem;
    color: var(--primary-color);
    font-weight: normal;
    background-color: #d58f76;
    border-radius: 5px;
    padding: 10px;
}

.gridImage {
    position: relative;
    overflow: hidden;
  transition: transform 0.3s ease;
}

.gridImage:hover {
    transform: scale(1.05);
  }

.gridImageCaption h1{
    position: absolute;
    right: 0%;
    bottom: 0%;
    color: white;
}

.gridImageCaption2 h1{
    position: absolute;
    left: 25%;
    bottom: 0%;
    color: white;
}

.gridImageCaption3 h1{
    position: absolute;
    right: 0%;
    bottom: 0%;
    color: white;
}


.gridImageCaption4 h1 {
    position: absolute;
    left: 0%;
    bottom: 0%;
    color: white;
}

.gridImageCaption5 h1{
    position: absolute;
    left: 20%;
    top: 70%;
    color: white;
}

.gridImageCaption6 h1 {
    position: absolute;
    left: 0%;
    bottom: 0%;
    color: white;
}



@media screen and (max-width:940px) {
    .imageGrid {
        flex-wrap: wrap;
    }

    .sectionBg {
        height: auto;
      }
    
    .hideMe {
        display: none;
    }

    .gridCenter .hide {
        display: block;
        padding: 2rem 0;
    }
    
    .imageGridHeader .hide {
        display: none;
    }

}