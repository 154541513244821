@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.opSection {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .opSection:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }


.opSection-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 5rem 1rem;
}

.opSection-wrap-left {
    width: 600px;
    margin: auto;

}

.opSection-wrap-left h1 {
    font-size: 2rem;
    text-align: center;
    color: rgb(15, 15, 15);
    padding: 1REM;
}

.opSection-wrap-right {
    width: 600px;
    margin: auto;
    padding: 0 1rem;
}


.opSection-wrap-right p {
    font-size: 16px;
    text-align: justify;
}

.opSection-wrap-right h1 {
    font-size: 1rem;
}

/* WRAP 2 */


.opSection-wrap2 {
    background: url('../../assets/section_bg2.webp') no-repeat center center/cover;
    padding: 10rem 0;
}

  .opSection-wrap2 h1 {
    font-size: 2rem;
    color: #d58f76;
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
}

.opSection-wrap2 p {
    color: rgb(75, 71, 71);
    font-size: 16px;
}

.wrap2-content {
    padding: 0 2rem;
}

/* MEDIA QUERIES */

@media screen and (max-width:1200px) {
    .opSection-wrap-right {
        width: 90%;
    }



}



