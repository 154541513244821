.activities {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgb(78, 95, 139);
  }
  
  .activities:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .02;
  }

  .actvities-content {
    margin: 1rem 0;
    padding: 2rem 0;
    width: 100%;
  }

  .activities-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .activities-flex-left {
    width: 60%;
    margin: auto;
    padding: 1rem;
  }

  .activities-flex-left h1 {
    padding: 1rem 0;
    text-align: center;
    color: #d58f76;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1rem;
  }

  .activities-flex-left p {
    color: rgb(223, 233, 233);
  }

  .activities-flex-left ul {
    width: fit-content;
    padding: 1rem 0;
    color: rgb(223, 233, 233);
  }
  

  .activities-flex-right {
    padding: 1rem;
    width: 450px;
  }

  .activities-flex-right img {
    width: 100%;
    border-radius: 1rem;
  }

  @media  screen and (max-width:1200px) {
    .activities-flex-left {
        width: 100%;
      }

      .activities-flex-left ul {
        margin: auto;
      }

  }