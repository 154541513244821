@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.jobs {
    width: 100%;
    height: 80vh;
    background: rgba(21, 3, 27, 0.233);
    position: relative;
  }
  
  .jobs:before {
    content: '';
    position: absolute;
    background: url('../../assets/jobs_hero.webp') no-repeat center top/cover;
    height: 80vh;
    width: 100%;
    z-index: -1;
  }


.jobs .jobs-content {
    display: block;
    position: absolute;
    bottom: 20%;
    right: 10%;
    padding: 1rem;
    width: max-content;
}

.jobs h1 {
    color: white;
    font-size: 3rem;
    font-weight: normal;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .jobs .jobs-content {
        width: 100%;
        right: 0%;
    }

.jobs h1 {
    font-size: 2rem;
}
}