@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.contact-hero {
    width: 100%;
    height: 80vh;
    background: rgba(21, 3, 27, 0.233);
    position: relative;
  }
  
  .contact-hero:before {
    content: '';
    position: absolute;
    background: url('../../assets/contact-us-hero.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  
.contact-hero .contact-hero-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
    left: 3%;
}

.contact-hero h1 {
    color: white;
    font-size: 3rem;
    font-weight: normal;
}

.contact-hero h2 {
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 2rem;
    font-weight: normal;
    text-align: center;
}

.contact-hero p {
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
    padding: .5rem 0;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.contact-hero .contact-hero-content {
    width: 100%;
    padding: 1rem;
}
    


.contact-hero h1 {
    font-size: 2rem;
}
}