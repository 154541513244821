.dialectal {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgb(78, 95, 139);
  }
  
  .dialectal:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .02;
  }

  .dialectal-content {
    padding: 3rem 1rem;
    width: 100%;
  }

  .dialectal-flex {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .dialectal-left {
    width: 50%;
    margin: auto;
  }

  .dialectal-left h1 {
    padding: 1rem 0;
    text-align: center;
    color: #d58f76;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1rem;
  }

  .dialectal-left p {
   color: rgb(223, 233, 233);
    text-align: justify;  
  }

  .dialectal-right {
    width: 500px;
    margin: auto;
  }

  .dialectal-right img {
    width: 100%;
    border-radius: 1rem;
  }

  @media  screen and (max-width:940px) {

    .dialectal-flex {
      flex-wrap: wrap;
    }
  

    .dialectal-left {
        width: 100%;
      }


      
    .dialectal-right {
      width: 90%;
    }


      .dialectal-left h1 {
        text-align: center;
      }


  }