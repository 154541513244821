@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');

.section-bg {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
}

.section-bg:before {
  content: '';
  position: absolute;
  background: url('../../assets/section_bg2.webp') no-repeat center top/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 1;
}

#mentalHealthMenucards {
  padding: 5rem 1rem;
}

.mentalHealthMenucardsHeader {
  padding: 1rem;
}

.mentalHealthMenucardsHeader p {
  padding: 1rem;
  text-align: center;

}

.mentalHealthMenucardsHeader h1 {
  text-align: center;
  font-family: 'Roboto', serif;
  font-size: 1.5rem;
  letter-spacing: 5px;
  font-weight: normal;
}

/* CARDS */

.mentalHealthMenucardsFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.mentalHealthCard {
  width: 30%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.082);
}

.mentalHealthCard img {
  width: 100%;
  display: block;
}

.card-body {
  padding: 16px;
  transition: background-color 0.6s ease;
}

.mentalHealthCard:hover .card-body {
  background-color: var(--primary-color); /* Background color on hover */
}

.mentalHealthCard:hover .card-title {
  color: #f7f4f1; /* Text color of title on hover */
}

.mentalHealthCard:hover .card-caption {
  color: #f7f4f1; /* Text color of title on hover */
}

.mentalHealthCard:hover .card-text {
  color: #f5f0f0; /* Text color of text on hover */
}


.mentalHealthCard h1 {
  font-size: 1rem;
  color: rgb(61, 58, 58);
  text-align: center;
  padding: 1rem;
  font-weight: normal;
}

.mentalHealthCard p {
  color: rgb(61, 58, 58);
  text-align: center;
}

.mentalHealthCard .arrow a {
  color: var(--primary-color);
}



  .mentalHealthCard:hover button{
    background-color: var(--secondary-color);
    border: none;
    color: white;
  }
  
  .menu-btn {
    text-align: center;
  }
  


@media screen and (max-width:940px) {
  .mentalHealthCard {
    width: 45%;
  }
  
}


@media screen and (max-width:700px) {
  .mentalHealthMenucardsFlex {
    flex-wrap: wrap;
  }
  
  .mentalHealthCard {
    width: 80%;
  }

}







