

@font-face {
  font-family: "Hallenger";
  src: local("Hallenger"),
       url("../../components/fonts/Hallenger.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Gorgeous";
  src: local("Gorgeous"),
       url("../../components/fonts/Gorgeous.ttf") format("truetype");
  font-weight: bold;
}

.hero-bg {
  width: 100%;
  height: auto;
  position: relative;
  background-color: rgba(250, 235, 215, 0);
}

.hero-bg:before {
  content: '';
  position: absolute;
  background: url('../../assets/hero_bg.webp') no-repeat center top/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.8;
}

.landing-page {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.text-container h1 {
    font-size: 1.2rem;
    text-align: left;
    color: hsl(257, 41%, 9%);
    font-family: "Hallenger", sans-serif;    
    letter-spacing: 3px;
    padding: 1rem 0;
}

.text-container span {
  line-height: 0%;
  color: #d58f76;
}

.text-container h2 {
  font-size: 3rem;
  font-family: "Hallenger", sans-serif;    
  font-weight: normal;   
  color: #383636;
  line-height: 3rem;
}

.text-container p {
  padding: 1rem 0;
}

.text-container ul {
  display: flex;
  justify-content: left;
  gap: 1rem;
  list-style-type: none;
  padding: 0;
}

.text-container ul li {
  display: flex;
  align-items: center;
}

.text-container ul li svg {
  margin-right: 10px;
  flex-shrink: 0;
  opacity: 0.8;
}

.check {
  stroke: orange;
}

.image-container {
  width: 50%;
  height: 80dvh;
  background: rgba(0, 0, 0, 0);
  position: relative;
}

.image-container:before {
  content: '';
  position: absolute;
  background: url('../../assets/hero_right.webp') no-repeat center top/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.text-container {
  flex: 1;
  margin: auto;
  padding: 0 1rem;
  transform: translateY(20px); /* Start position */
  animation: fadeInUp 1s ease-out forwards; /* Apply animation */
}


@keyframes fadeInUp {
  to {
    opacity: 1; /* End opacity */
    transform: translateY(0); /* End position */
  }
}

.heroBtn {
  display: flex;
  justify-content: left;
  gap: 1rem;
  padding: 1rem 0;
  text-align: left;
}

.heroBtn .heroBtn1 button{
  width: 100%;
}


.heroBtn .heroBtn2 button{
  width: 100%;
  background-color: #d58f76;
}


.heroBtn button {
  background-color: var(--primary-color);
  color: white;
  width: 200px;
}

@media screen and (max-width:940px) {
  .landing-page {
    flex-wrap: wrap-reverse;
  }

  .image-container {
    width: 100%; /* Fixed width for the image container */
  }

  .text-container h1 {
    text-align: center;
}

.text-container h2 {
  text-align: center;
}

.text-container ul {
  justify-content: center;
}

.heroBtn {
  justify-content: center;
}

.text-container {
  padding: 0 1rem;
}

}


@media screen and (max-width:600px) {

  .image-container:before {
    height: 70%;
  }

  
  .text-container {
    margin-top: -45%;
  }


  .text-container h1 {
    text-align: center;
    font-size: 3rem;
    line-height: normal;
}

.text-container span {
  font-size: 3rem;
  line-height: normal;
}

.text-container h2 {
  font-size: 1.2rem;
  letter-spacing: 0px;
  padding: 0;
  line-height: normal;
}

.text-container ul {
  display: block;
}

.heroBtn {
  flex-wrap: wrap;
  gap: 0;
}

.hide {
  display: none;
}


}