@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.navbar {
    width: 100%;
    height: auto;
    z-index: 10;
    background-color: rgba(250, 235, 215, 0);
    position: relative;
    text-align: center;
    padding: 1rem 0;
}

.nav-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}


.navbar-logo {
  z-index: 2;
  width: 250px;
  margin: auto 0;
}

.navbar-logo img {
  width: 100%;
  height: auto;
  padding: .5rem;
}

.navbar .icon {
  font-size: 2rem;
  color: var(--primary-color);
}

 .navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
} 

.hamburger {
    display: none;
    padding: 1rem;
    margin: auto 0;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    margin: auto 0;
}

  .navbar ul {
    margin: auto;
  }

.navbar li {
    width: max-content;
    padding: .3rem 1rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}


.navbar li a {
  color: #302d2c;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

.navbar li a:hover {
  color: rgb(211, 124, 11);
}

  .menu-item .sub__menus li a {
    font-size: 13px;
    padding: -1rem ;
  }

  .menu-item .sub__menus li a:hover {
    color: rgb(255, 123, 0) !important;
  }

  li .submenu-header {
    font-size: 14px;
    color: rgb(36, 131, 27);
    font-weight: bolder;
    text-decoration: underline;
    cursor: pointer;
  }


/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #ebe4e4;
    border: none;
    border-radius: 5px;
    width: auto;
    margin-top: 6px;
    z-index: 1000;
  }




  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows {
    position: relative;
  }

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 7px;
  }



@media screen and (max-width: 1200px) {

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    top: 10px;
    font-size: 12px;
  }

  .navbar .hamburger {
    display: block;
    z-index: 15;
}

    .navbar ul {
      margin: auto 0;
    }

    .navbar li a {
      color: rgb(32, 31, 31);
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 80%;
        height: 100vh;
        top: 0;
        left:-100%;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        transition: 0.5s ease-in;
        color: white;
    }

    .navbar .sub__menus {
      padding: 1rem 0;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }


    .menu-item .sub__menus li a {
        font-size: 14px;
      }

      li .submenu-header {
        font-size: 14px;
      }


      #submenu-item ul {
        width: 100%;
      }


      #submenu-item li {
        padding: .2rem 1rem;
        border-bottom: 1px dashed black;
      }

}



@media screen and (max-width: 1200px) { 
  .sub__menus {
    width: 100%;
  }
}


@media screen and (max-width: 600px) {
  .sub__menus {
    position: absolute;
    display: none;
    border-radius: 3px;
    width: 100%;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
    left: 0;
  }

  .navbar li {
    width: 100%;
}
}