.interpersonal {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgb(78, 95, 139);
  }
  
  .interpersonal:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .02;
  }

  .interpersonal-content {
    padding: 1rem;
    width: 100%;
  }

  .interpersonal-flex {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .interpersonal-left {
    width: 100%;
    margin: auto;
  }

  .interpersonal-left h1 {
    padding: 1rem 0;
    text-align: center;
    color: #d58f76;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1rem;
  }

  .interpersonal-left p {
    color: rgb(223, 233, 233);
    text-align: justify;  
  }

  .interpersonal-right {
    padding: 1rem;
    width: 100%;
    margin: auto;
  }

  .interpersonal-right img {
    width: 100%;
    border-radius: 1rem;
  }

  @media  screen and (max-width:940px) {
    .interpersonal-flex {
      flex-wrap: wrap;
    }
  
      .interpersonal-left h1{
        text-align: center;
      }


  }