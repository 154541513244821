@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.treatment {
  width: 100%;
  height: 80vh;
  background: rgba(21, 3, 27, 0.233);
  position: relative;
  }
  
  .treatment:before {
    content: '';
    position: absolute;
    background: url('../../assets/treatment-hero.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .treatment-content {
    display: block;
    position: absolute;
    bottom: 20%;
    left: 5%;
    padding: 1rem;
    width: max-content;
}

.treatment h1 {
  color: white;
  font-size: 3rem;
  font-weight: normal;
}

.treatment p {
  font-family: 'Montserrat', sans-serif;
  color: white;
  text-align: center;
  padding: .5rem 0;
}



/* MEDIA QUERIES - HERO */

@media screen and (max-width:940px) {

.treatment h1 {
    font-size: 2.5rem;
    text-align: center;
}

.treatment-content {
  display: block;
  position: absolute;
  left: 0%;
  padding: 1rem;
  width: 100%;
}


}

