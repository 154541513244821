@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.insurance {
  width: 100%;
  height: 80vh;
  background: rgba(21, 3, 27, 0.233);
  position: relative;
}
  
  .insurance:before {
    content: '';
    position: absolute;
    background: url('../../assets/insurance-hero.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.insurance .insurance-content {
    display: block;
    position: absolute;
    bottom: 20%;
    right: 10%;
    padding: 1rem;
    width: max-content;
}

.insurance h1 {
  color: white;
  font-size: 3rem;
  font-weight: normal;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {


.insurance .insurance-content {
    width: 100%;
    right: 0%;
}

.insurance .insurance-content h1 {
  text-align: center;
}
}