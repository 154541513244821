.topbar {
    width: 100%;
    padding: .7rem;
    background-color: #120d1f;
}

.topbar .topbar-content {
    display: flex;
    justify-content:space-between;
    color: white;
    text-align: center;
}

.bar {
    width:max-content;
}

.bar span {
    color: white;
}

.bar1 {
    text-align: left;
}

.bar2 {
    width: max-content;
}

.bar3 {
    text-align: right;
}

.topbar .topbar-content p {
    padding: 0 1rem;
    font-size: 14px;
}


@media screen and (max-width:800px) {
    .barHide {
        display: none;
    }
    .topbar .topbar-content {
        justify-content: center;
    }
}