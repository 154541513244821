.iop {
    width: 100%;
    height: 70vh;
    background: rgba(0, 0, 0, 0.507);
    position: relative;
  }
  
  .iop:before {
    content: '';
    position: absolute;
    background: url('../../assets/iop_hero.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.iop-content {
    width: 100%;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.iop h1 {
    color: rgba(255, 255, 255, 0.849);
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-weight: normal;
}

.iop p {
    color: white;
    font-size: .8rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    
    .iop-content {
        bottom: 10%;
    }
    
    .iop h1 {
        font-size: 2rem;
    }
    
    }