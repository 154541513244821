@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.substanceAbuse {
    width: 100%;
    height: auto;
    position: relative;
}
  
  .substanceAbuse:before {
    content: '';
    position: absolute;
    background: url('../../assets/section_bg2.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.substanceAbuseContent {
    padding: 2rem 1rem;
}


/* HEADER */

.substanceAbuseHeader {
    text-align: center;
    padding: 2rem 0;
}

.substanceAbuseHeader h1 {
    color: var(--primary-color);
    font-weight: normal;
    font-size: 1.5rem;
    padding: 1rem 0;
}

.substanceAbuseHeader p {
    color: rgb(73, 67, 67);
    font-size: 14px;
}


/* RIGHT */


 .substanceAbuseFlex {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    padding: 2rem 0;
}

.flexItemIcon {
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.flexItemIcon img {
    width: 100%;
}



/* FLEX ITEMS */

.substanceAbuseFlexItem {
    height: auto;
    width: 280px;
    background-color: rgba(255, 255, 255, 0.795);
    border: 1px solid rgba(110, 99, 99, 0.164);
    padding: .5rem ;
    border-radius: 10px;
    padding: 2rem 1rem;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.substanceAbuseFlexItem:hover {
    transform: scale(1.05);
}

.substanceAbuseFlexItem h1 {
    color: var(--primary-color);
    font-weight: normal;
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;
    text-align: center;
    width: 100%;
    padding: 1rem;
}

.substanceAbuseFlexItem p {
    color: var(--primary-color);
    text-align: center;
}


.sa-btn {
    margin: auto;
    text-align: center;
}


 .sa-btn button {
    cursor: pointer;
    background-color: var(--primary-color);
    font-size: 1rem;
    font-weight: 600;
    color: white;
    font-weight: normal;
}

.sa-btn button:hover {
    cursor: pointer;
    background-color: rgb(25, 53, 104);
    color: white;
}





@media screen and (max-width:600px) {

    .substanceAbuseFlexItem {
        width: 100%;
    }
    
}

